:root {
    --theme-color-1: #1976d2;
    --primary:#f44336
}

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 20px 0px;
}

h1 {
    font-size: 34px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

a:hover {
    color: var(--theme-color-1);
    text-decoration: none;
}

a.active {
    color: var(--theme-color-1) !important;
}

.navbar {
    padding: 0 !important;
    background-color: #fff !important;
    box-shadow: 0px -6px 14px #888888;
}

.navbar-light .navbar-nav .nav-link {
    color: #000 !important;
    font-size: 16px;
}

.align-center {
    align-items: center;
}

.bg-dark {
    /* background-color: #353535 !important; */
}

.container-box {
    width: 100%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
}

.sideNav-sidePanel {
    position: sticky !important;
    z-index: 1000;
    height: calc(100vh - 62px);
    background-color: #f7f7f7;
    border-left: 1px solid #ececec;
    overflow-y: auto;
}

.sideNav-sidePanel form i {
    display: none;
}

.sideNav-sidePanel>ul {
    border-top: 1px solid #ddd;
    margin-top: 10px;
    padding: 10px 0px;
}

.sideNav-sidePanel ul {
    margin: 0;
}

.sideNav-sidePanel ul li {
    direction: rtl;
    list-style: none;
    font-size: 14px;
    padding: .5rem 1rem;
}

.sideNav-sidePanel ul li a {
    color: #000;
}

.sideNav-sidePanel ul ul {
    padding-left: 15px;
}

.sideNav-sidePanel ul ul li a {
    color: rgba(0, 0, 0, .6);
}

.main-styles {
    order: 1;
    padding: 0px 1rem;
    background: #fff;
    height: calc(100vh - 62px);
    overflow-y: scroll;
}

.Result {
    border: 1px solid #ddd;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    margin-top: -15px;
}

.Result>.header {
    padding: 15px 15px;
    background: rgb(40, 44, 52);
    color: #fff
}

.Result>.body {
    padding: 30px 15px;
    text-align-last: center;
}

.TabsCode {
    background: #fff;
    margin-top: 15px;
}

.tab-content {
    background-color: #fff !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.document {
    overflow-x: auto;
}

.document table {
    width: 100%;
    text-align-last: center;
    /* table-layout: fixed; */
}

.document table th {
    padding: 9px 13px;
    line-height: 1.4;
    border-bottom: 2px solid #dee2e6;
    border: 1px solid #dee2e6;
    white-space: pre;
}

.document table td {
    padding: 9px 13px;
    line-height: 1.4;
    border: 1px solid #dee2e6;
}

td.td-values {
    white-space: pre;
}

.document table tr:nth-child(2n) td {
    background-color: #f4f4f4;
}

.line-height-30 {
    line-height: 30px;
}

.home-banner {
    background-image: #fff;
    color: #000;
    position: relative;
}

.home-banner>aside {
    height: calc(100vh - 62px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-banner h1 {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    color:  #000;
}

.home-banner p {
    width: 100%;
    text-align: center;
    margin: 0;
    color:  #000;

}

.get-started-btn {
    padding: 10px 65px;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: var(--primary);
    color: #fff;
    font-weight: 400;
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;

}

.get-started-btn:hover {
    background: #eee;
    color: #000;
}

.get-started-box {
    padding-top: 65px;
    display: flex;
    justify-content: center;
}

.Code-Editor-Box {
    border-radius: 10px 10px 0px 0px;
    min-height: 100px;
    text-align-last: left;
    background-color: rgb(40, 44, 52) !important;
    direction: ltr;
    text-align: left !important;
    font-size: 14px;
}

.Code-Editor-Box textarea:focus {
    caret-color: #fff;
}

.Code-Error-Box {
    text-align-last: left;
    direction: ltr;
    color: #721c24;
    background-color: #f8d7da;
    padding: 15px;
    font-size: 14px;
    border: 2px solid #f5c6cb;
    margin: 0;
}

.Result-Box {
    border-radius: 0px 0px 10px 10px;
    margin-top: 0px;
    min-height: 100px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    padding: 15px;
}

.markdown code.language-javascript,
.language-html {
    border-radius: 2px;
    min-height: 50px;
    text-align-last: left;
    background-color: rgb(40, 44, 52) !important;
    color: #fff;
    direction: ltr;
    text-align: left !important;
    font-size: 14px;
    padding: 15px;
    display: block;
}

.markdown li {
    margin-bottom: 10px;
}

.text-center {
    text-align-last: center;
}

.grey-color {
    background-color: #dddddd !important;
}

.iframe {
    border: none;
    width: 100%;
    min-height: 300px;
}

.nav-tabs .nav-link {
    border: none;
    color: rgba(0, 0, 0, .87);
    min-width: 160px;
    opacity: 0.6;
    text-align: center;
}

.nav-tabs .nav-link.active {
    border-bottom: 2px solid var(--theme-color-1);
}

.justify-space-between {
    justify-content: space-between;
}

.codeButton {
    cursor: pointer;
}

.nav-tabs {
    max-width: 100%;
    margin: 0;
    border-bottom: 1px solid #dcdcdc;
}

.card {
    border-radius: 2px;
}

.sidebar-menu {
    background: transparent;
    border: none;
    display: none;
    margin-left: 10px;
}

@media (min-width: 1280px) {
    .home-banner h1 {
        font-size: 60px;
    }

    .home-banner p {
        padding-top: 20px;
        font-size: 30px;
    }

    .home-banner>aside {
        padding-top: 95px;
        padding-bottom: 85px;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
}

@media (max-width: 1280px) {
    .home-banner>aside {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .home-banner h1 {
        font-size: 50px;
    }

    .home-banner p {
        margin-top: 20px;
    }

    .get-started-box {
        padding-top: 40px;
    }

    .get-started-btn {
        font-size: 12px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .home-banner>aside {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .home-banner h1 {
        font-size: 30px;
    }

    .home-banner p {
        margin-top: 15px;
    }

    .get-started-box {
        padding-top: 20px;
    }

    .get-started-btn {
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .document {
        white-space: nowrap
    }

    .sideNav-sidePanel {
        height: auto
    }

    .sideNav-sidePanel form i {
        display: block;
    }

    .sideNav-sidePanel>ul {
        display: none;
    }

    .sideNav-sidePanel>ul.show {
        display: block;
    }

    .sidebar-menu {
        display: inline-block;
    }
}